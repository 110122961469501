<script>
import api from '@/modules/api'

export default {
  name: "IspOnuSignalRealtime",
  props: {
    onuId: {
      type: Number,
    },
  },
  data() {
    return {
        interval: undefined,
        timeLeft: 0,
        updating: false,
        oltSide: 0,
        onuSide: 0,
    }
  },
  methods: {
    refreshOnuSignals() {
      this.timeLeft--;
      if (this.timeLeft > 0) {
        return;
      }

      this.doRefreshSignal();
    },
    refreshNowHandle() {
      this.doRefreshSignal();
    },
    doRefreshSignal() {
      this.timeLeft = 30;
      this.updating = true;
      this.oltSide = 0;
      this.onuSide = 0;
      api.get(`/isp/onus/${this.onuId}/realtime/signals`).then(res => {
        this.oltSide = res.data.oltSide;
        this.onuSide = res.data.onuSide;
        this.updating = false;
      }).catch(() => {
        this.updating = false;
      });
    },
  },
  created() {
    this.interval = setInterval(this.refreshOnuSignals, 1000);
    this.refreshOnuSignals();
  },
  watch: {
    onuId: function(newVal) {
      if (!newVal) {
        clearInterval(this.interval);
      }
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>


<template>
  <div>
    <!-- card signal -->
    <div class="card bg-danger text-white h-100">
        <div class="card-header py-1">Sinal realtime</div>
        <div class="card-body text-end py-2">
        <h5 class="card-title float-start">
            <span class="icone">
            <font-awesome-icon :icon="['fas', 'wave-square']" size="2x" fixed-width class="me-1"/>
            </span>
        </h5>
        <div class="row g-2">
            <div class="col">
            <p class="card-text mb-0 fw-bold">Leitura OLT</p>
            <p class="card-text mb-0 fw-bold">Leitura ONU</p>
            </div>
            <div class="col-auto">
            <p class="card-text mb-0">{{ oltSide / 100 || '-'}}</p>
            <p class="card-text mb-0">{{ onuSide / 100 || '-'}}</p>
            </div>
        </div>
        </div>
        <div class="card-footer text-center p-0">
        <font-awesome-icon v-if="(updating)" :icon="['fas', 'spinner']" spin fixed-width class="me-1"/>
        <span v-if="(!updating)" @click="refreshNowHandle()" class="cursor-pointer">próxima leitura em {{timeLeft}}s...</span>
        </div>
    </div>
    <!-- card signal -->
  </div>
</template>

