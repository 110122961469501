<script>
import api from '@/modules/api'
import loading from '@/modules/loading'
import dialogo from '@/modules/dialogo'
import IspOnuSignalRealtime from "@/components/IspOnuSignalRealtime.vue";

export default {
  name: "IspOnuApprove",
  props: {
    onuId: {
      type: Number,
    },
    closeFunc: {
      type: Function,
      required: true,
    },
  },
  components: {
    IspOnuSignalRealtime,
  },
  data() {
    return {
      onu: {
        id: undefined,
        oltId: 0,
        rack: 0,
        chassi: 0,
        slot: 0,
        ponPort: 0,
        index: 0,
        description: "",
        oltInternalName: "",
        serialNumber: "",
        mainVlan: 0,
        oltOnuTypeDetected: "",
        oltOnuTypeRegistered: "",
      },
      config: {
        onuTypeRequired: false,
        onuTypeSuggest: '',
        downstreamProfileRequired: false,
        downstreamProfileSuggest: '',
        upstreamProfileRequired: false,
        upstreamProfileSuggest: '',
        veipPorts: [],
        bridgePorts: [],
        downstreamProfiles: [],
        upstreamProfiles: [],
        onuTypes: [],
      },
      form: {
        configMode: 'wizard', //wizard / advanced
        description: '',
        vlan: undefined,
        bridgeRouter: undefined,
        onuType: '',
        downstreamProfile: undefined,
        upstreamProfile: undefined,
      },
      vlanList: [],
    }
  },
  methods: {
    async initApprove(onuId) {
      this.resetForm();
      loading(true);

      // load onu
      await api.get(`/isp/onus/${onuId}/approve-config`).then(res => {
        this.onu = res.data.onu;
        this.config = res.data.config;
      }).catch(error => {
        loading(false);
        this.closeFunc();
        dialogo.erroHttp(error);
      });

      // vlans
      await api.get(`/isp/olts/${this.onu.oltId}/vlans?paginate.cancel&orderBy[vlan_id]=asc`).then(res => {
        this.vlanList = res.data.items;
        if (this.vlanList.length === 1) {
          this.form.vlan = this.vlanList[0].vlanId
        }
      }).catch(error => {
        loading(false);
        this.closeFunc();
        dialogo.erroHttp(error);
      });

      // load dependencies: onu types, upload and download profiles list, etc

      this.applyOnuConfig();
      document.getElementById('btn-modal-approve-onu-abre').click();
      loading(false);
    },

    resetForm() {
      this.form.configMode = 'wizard';
      this.form.description = '';
      this.form.vlan = undefined;
      this.form.bridgeRouter = 'bridge';// undefined; // TODO: REMOVER
      this.form.onuType = '';
      this.form.downstreamProfile = undefined;
      this.form.upstreamProfile = undefined;
    },
    applyOnuConfig() {
      this.form.description = this.onu.description;

      // download profile
      if (this.config.downstreamProfileSuggest) {
        this.form.downstreamProfile = this.config.downstreamProfileSuggest;
      }
      // upload profile
      if (this.config.upstreamProfileSuggest) {
        this.form.upstreamProfile = this.config.upstreamProfileSuggest;
      }
      // onu type
      if (this.config.onuTypeSuggest) {
        this.form.onuType = this.config.onuTypeSuggest;
      }

      // vlans
    },

    showVlan() {
      return (this.vlanList.length > 1);
    },
    showOnuType() {
      return (this.config.onuTypeRequired && !this.config.onuTypeSuggest);
    },
    showDownloadProfile() {
      return (this.config.downstreamProfileRequired && !this.config.downstreamProfileSuggest);
    },
    showUploadProfile() {
      return (this.config.upstreamProfileRequired && !this.config.upstreamProfileSuggest);
    },

    canApprove() {
      switch (this.form.configMode) {
        case 'wizard':
          return this.canApproveWizard();

        case 'advanced':
          return this.canApproveAdvanced();

        default:
          return false;
      }
    },
    canApproveWizard() {
      switch (true) {
        case (this.form.description.length < 3):
          return false;

        case (!this.form.vlan):
          return false;

        case (!this.form.bridgeRouter):
          return false;

        case (this.config.onuTypeRequired && !this.form.onuType):
          return false;

        case (this.config.downstreamProfileRequired && !this.form.downstreamProfile):
          return false;

        case (this.config.upstreamProfileRequired && !this.form.upstreamProfile):
          return false;
      }

      return true;
    },
    canApproveAdvanced() {
      return false;
    },
    approveHandle() {
      switch (this.form.configMode) {
        case 'wizard':
          return this.approveWizard();

        case 'advanced':
          return this.approveAdvanced();
      }
    },
    approveWizard() {
      let data = {
        description: this.form.description,
        downstreamProfile: this.form.downstreamProfile,
        upstreamProfile: this.form.upstreamProfile,
        onuType: this.form.onuType,
      };

      switch (this.form.bridgeRouter) {
        case 'router':
          data.veipVlans = [{
            port: this.config.veipPorts[0],
            vlan: this.form.vlan,
          }];
          break;

        case 'bridge':
          data.bridgeVlans = [{
            port: this.config.bridgePorts[0],
            vlan: this.form.vlan,
          }];
          break;
      }

      this.approveRequest(data);
    },
    approveAdvanced() {
      dialogo.alerta("Ainda não implementado");
    },
    approveRequest(data) {
      loading(true);
      api.post(`/isp/onus/${this.onuId}/approve`, data).then(() => {
        loading(false);
        this.close();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    unregisterHandle() {
      dialogo.confirmacao(`Confirma remover a ONU <b>${this.onu.serialNumber}</b>?`).then(() => {
        loading(true);

        api.delete(`/isp/onus/${this.onuId}`).then(() => {
          loading(false);

          this.close();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    close() {
      document.getElementById('btn-modal-approve-onu-fecha').click();
    },
  },
  created() {
  },
  mounted() {
    const _vm = this;
    const myModalEl = document.getElementById('modal-approve-onu');

    myModalEl.addEventListener('hide.bs.modal', function () {
      _vm.onu.id = undefined;
      _vm.closeFunc();
    });
  },
  watch: {
    onuId: function(newVal) { // watch it
      if (newVal) {
        this.initApprove(newVal);
      }
    },
  },
};
</script>

<style scoped>
.nav-link.active {
  background-color: rgba(0, 0, 0, 0.03);
  color: #0d6efd;
  /* background-color: #0d6efd; */
  /* color: white; */
}
.nav-link {
  color: #666;
  /* background-color: #0d6efd; */
  /* color: white; */
}
</style>

<template>
  <div>
    <!-- modal-approve-onu -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-approve-onu" id="btn-modal-approve-onu-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-approve-onu" tabindex="-1" role="dialog" aria-labelledby="modalOnuInfo" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin scroll" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'check-double']" fixed-width/>
              Aprovar ONU
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div v-if="(onu.id)" class="modal-body">
            <div class="row">
              <div class="col-12 col-sm-6 mb-3">
                <!-- card info -->
                <div class="card h-100">
                  <div class="card-header py-1">
                    Dados da onu
                    <span class="icone float-end">
                      <font-awesome-icon :icon="['fas', 'info-circle']" fixed-width class="text-muted me-1"/>
                    </span>
                  </div>
                  <div class="card-body py-2">
                    <div class="row g-2">
                      <div class="col-auto text-end">
                        <p class="card-text mb-0 fw-bold">Olt</p>
                        <p class="card-text mb-0 fw-bold">Porta</p>
                        <p class="card-text mb-0 fw-bold">Descrição</p>
                        <p class="card-text mb-0 fw-bold">S.N.</p>
                      </div>
                      <div class="col-auto">
                        <p class="card-text mb-0">Olt XYZ</p>
                        <p class="card-text mb-0">[{{onu.chassi}}/{{onu.slot}}/{{onu.ponPort}}:{{onu.index}}]</p>
                        <p class="card-text mb-0">{{onu.description}}</p>
                        <p class="card-text mb-0">{{onu.serialNumber}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer text-center p-0 d-none d-sm-block">
                    &nbsp;
                  </div>
                </div>
                <!-- card info -->
              </div>

              <div class="col-12 col-sm-6 mb-3">
                <!-- card signal -->
                <IspOnuSignalRealtime v-if="(onu.id)" :onuId="onu.id"/>
                <!-- card signal -->
              </div>


              <div class="col-12">
                <ul class="nav nav-tabs nav-justified">
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" data-bs-toggle="tab" href="#nav-wizard" role="tab" aria-controls="nav-wizard" aria-selected="true">
                      <font-awesome-icon :icon="['fas', 'magic']" fixed-width class="me-1"/>
                      Assistente rápido
                    </a>
                  </li>
                  <li class="nav-item ms-1">
                    <a class="nav-link disabled" aria-current="page" data-bs-toggle="tab" href="#nav-advanced" role="tab" aria-controls="nav-advanced" aria-selected="true">
                      <font-awesome-icon :icon="['fas', 'cogs']" fixed-width class="me-1"/>
                      Avançado
                    </a>
                  </li>
                </ul>
                <div class="tab-content border-start border-end border-bottom px-3" id="nav-tabContent">
                  <!-- wizard -->
                  <div class="tab-pane fade show active" id="nav-wizard" role="tabpanel" aria-labelledby="nav-wizard-tab">
                    <div class="row">
                      <div class="col-12">
                        <div class="mb-3">
                          <label for="description" class="form-label mb-0">Identificação</label>
                          <input v-model="form.description" type="text" class="form-control" id="description" placeholder="">
                        </div>
                      </div>
                      <div v-if="showVlan()" class="col-12">
                        <div class="mb-3">
                          <label for="vlan" class="form-label mb-0">Vlan</label>
                          <select v-model="form.vlan" class="form-select" id="vlan">
                            <option :value="undefined" disabled>Selecione</option>
                            <option v-for="vlan in vlanList" :key="vlan.id" :value="vlan.vlanId">
                              {{ vlan.vlanId }}
                              <span v-if="(vlan.description)"> - {{ vlan.description }}</span>
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mb-3">
                          <label for="bridge-router" class="form-label mb-0">Modo de operação</label>
                          <select v-model="form.bridgeRouter" class="form-select" id="bridge-router">
                            <option :value="undefined" disabled>Selecione</option>
                            <option value="bridge">Bridge</option>
                            <option value="router">Router</option>
                          </select>
                        </div>
                      </div>
                      <div v-if="showOnuType()" class="col-12">
                        <div class="mb-3">
                          <label for="onu-type" class="form-label mb-0">Modelo da ONU</label>
                          <select v-model="form.onuType" class="form-select" id="onu-type">
                            <option :value="undefined" disabled>Selecione</option>
                            <option v-for="type in config.onuTypes" :key="type" :value="type">{{ type }}</option>
                          </select>
                        </div>
                      </div>
                      <div v-if="showDownloadProfile()" class="col-12">
                        <div class="mb-3">
                          <label for="download-profile" class="form-label mb-0">Download</label>
                          <select v-model="form.downstreamProfile" class="form-select" id="download-profile">
                            <option :value="undefined" disabled>Selecione</option>
                            <option v-for="profile in config.downstreamProfiles" :key="profile" :value="profile">{{ profile }}</option>
                          </select>
                        </div>
                      </div>
                      <div v-if="showUploadProfile()" class="col-12">
                        <div class="mb-3">
                          <label for="upload-profile" class="form-label mb-0">Upload</label>
                          <select v-model="form.upstreamProfile" class="form-select" id="upload-profile">
                            <option :value="undefined" disabled>Selecione</option>
                            <option v-for="profile in config.upstreamProfiles" :key="profile" :value="profile">{{ profile }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- wizard -->

                  <!-- advanced -->
                  <div class="tab-pane fade" id="nav-advanced" role="tabpanel" aria-labelledby="nav-advanced-tab">
                    <div class="row">
                      <div class="col-12">
                        Em desenvolvimento...
                      </div>
                    </div>
                  </div>
                  <!-- advanced -->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-approve-onu-fecha">Cancelar</button>
            <button type="button" @click="unregisterHandle()" class="btn btn-outline-danger">
              <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
              Remover
            </button>
            <button type="button" @click="approveHandle()" :disabled="!canApprove()" class="btn btn-success">
              <font-awesome-icon :icon="['fas', 'check-double']" fixed-width/>
              Aprovar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-approve-onu -->
  </div>
</template>

